var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('InfoBanner',{attrs:{"module_name":this.module_name}}),_c('PageTitle',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" Заявки на курсы ")]},proxy:true},{key:"custom-btn",fn:function(){return [_c('v-btn',{attrs:{"color":"accent darken-4","type":"submit","depressed":"","to":_vm.go_to}},[_vm._v(" Создать заявку ")])]},proxy:true}])}),(_vm.info_message())?_c('InfoAlert',{staticClass:"mb-6",attrs:{"is_dark":""},scopedSlots:_vm._u([{key:"alert_text",fn:function(){return [_c('div',{domProps:{"innerHTML":_vm._s(_vm.info_message())}})]},proxy:true}],null,false,631939790)}):_vm._e(),_c('v-card',{staticClass:"pa-6"},[_c('Filters',{class:_vm.$style.filters,on:{"filter_update":_vm.filter_update},scopedSlots:_vm._u([{key:"btn-outer",fn:function(){return [_c('v-btn',{class:[_vm.$style.btnOuter , 'mb-0'],attrs:{"text":""},on:{"click":function($event){_vm.cols_dialog = true}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-tune")]),_c('span',[_vm._v("Настройка полей")])],1)]},proxy:true}])}),_vm._l((_vm.filtered_cols),function(header,idx){return _c('ResizeCols',{key:idx,attrs:{"header":header,"storage_name":_vm.cols_storage_name}})}),_c('Table',{class:_vm.$style.table,attrs:{"headers":_vm.filtered_cols,"items":_vm.courses_requests,"options":_vm.table_options,"custom-sort":_vm.prevent_sort,"loading":_vm.courses_requests_loading,"no-data-text":"Заявки на курсы, удовлетворяющие выбранным параметрам, отсутствуют","hide-default-footer":""},on:{"update:options":function($event){_vm.table_options=$event},"click:row":_vm.row_click_handler},scopedSlots:_vm._u([{key:"item.external_number",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"py-2",class:_vm.row_class(item)},[_vm._v(" "+_vm._s(item.external_number)+" ")])]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"py-2",class:_vm.row_class(item)},[_vm._v(" "+_vm._s(_vm._f("date")(item.date))+" ")])]}},{key:"item.name_organization",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"py-2",class:_vm.row_class(item)},[_vm._v(" "+_vm._s(item.name_organization)+" ")])]}},{key:"item.quantity_courses",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"py-2",class:_vm.row_class(item)},[_vm._v(" "+_vm._s(item.quantity_courses)+" ")])]}},{key:"item.quantity_staff",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"py-2",class:_vm.row_class(item)},[_vm._v(" "+_vm._s(item.quantity_staff)+" ")])]}},{key:"item.total_amount",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"py-2",class:_vm.row_class(item)},[_vm._v(" "+_vm._s(_vm._f("currency_rub")(item.total_amount))+" ")])]}},{key:"item.order_number",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"py-2",class:_vm.row_class(item)},[_vm._v(" "+_vm._s(item.order_number)+" ")])]}},{key:"item.updated_at",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"py-2",class:_vm.row_class(item)},[_vm._v(" "+_vm._s(_vm._f("date")(item.updated_at))+" ")])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('td',{class:_vm.row_class(item)},[_c('ChipStatus',{class:_vm.$style.chip,attrs:{"status":_vm.get_course_status(item)}},[_vm._v(" "+_vm._s(item.status ? item.status.name : '')+" ")])],1)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('td',{class:_vm.row_class(item)},[(_vm.edit_access(item))?[_c('v-btn',{attrs:{"icon":"","small":"","color":"accent darken-4"},on:{"click":function($event){$event.stopPropagation();return _vm.go_to_edit(item)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1),_c('v-btn',{staticClass:"ml-2",attrs:{"icon":"","small":"","color":"secondary darken-4"},on:{"click":function($event){$event.stopPropagation();return _vm.remove_item(item)}}},[_c('v-icon',[_vm._v("mdi-delete-outline")])],1)]:_vm._e()],2)]}},{key:"footer",fn:function(){return [_c('Pagination',{attrs:{"pagination_props":_vm.pagination_props},on:{"change_page":function($event){return _vm.upd_pagination('current_page', $event)},"page_size_changed":function($event){return _vm.upd_pagination('limit', $event)}}})]},proxy:true}],null,true)})],2),_c('CustomColsDialog',{attrs:{"cols":_vm.cols,"cols_list":_vm.cols,"storage_name":_vm.cols_storage_name,"default_cols":_vm.cols_default},on:{"update:cols_list":function($event){_vm.cols=$event},"save_cols":function($event){_vm.cols_dialog = false},"cancel_dialog":function($event){_vm.cols_dialog = false},"close":function($event){_vm.cols_dialog = false}},model:{value:(_vm.cols_dialog),callback:function ($$v) {_vm.cols_dialog=$$v},expression:"cols_dialog"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }